export const summaryPageTopMenu = {
	intro: { id: 'intro', text: 'Intro', path: '#intro', icon: 'Vrpano', subMenu: null },
	bootstrap: {
		id: 'bootstrap',
		text: 'Bootstrap Components',
		path: '#bootstrap',
		icon: 'BootstrapFill',
		subMenu: null,
	},
	storybook: {
		id: 'storybook',
		text: 'Storybook',
		path: '#storybook',
		icon: 'CustomStorybook',
		subMenu: null,
	},
	formik: {
		id: 'formik',
		text: 'Formik',
		path: '#formik',
		icon: 'CheckBox',
		subMenu: null,
	},
	apex: {
		id: 'apex',
		text: 'Apex Charts',
		path: '#apex',
		icon: 'AreaChart',
		subMenu: null,
	},
};

export const componentPagesMenu = {
	bootstrap: {
		id: 'bootstrap',
		text: 'Bootstrap',
		icon: 'Extension',
	},
	components: {
		id: 'components',
		text: 'Component',
		path: 'components',
		icon: 'Extension',
		notification: 'success',
		subMenu: {
			accordion: {
				id: 'accordion',
				text: 'Accordion',
				path: 'components/accordion',
				icon: 'AccountTree',
			},
			alert: {
				id: 'alert',
				text: 'Alert',
				path: 'components/alert',
				icon: 'AccountTree',
			},
			badge: {
				id: 'badge',
				text: 'Badge',
				path: 'components/badge',
				icon: 'AccountTree',
			},
			breadcrumb: {
				id: 'breadcrumb',
				text: 'Breadcrumb',
				path: 'components/breadcrumb',
				icon: 'AccountTree',
			},
			button: {
				id: 'button',
				text: 'Button',
				path: 'components/button',
				icon: 'AccountTree',
			},
			buttonGroup: {
				id: 'buttonGroup',
				text: 'Button Group',
				path: 'components/button-group',
				icon: 'AccountTree',
			},
			card: {
				id: 'card',
				text: 'Card',
				path: 'components/card',
				icon: 'AccountTree',
			},
			carousel: {
				id: 'carousel',
				text: 'Carousel',
				path: 'components/carousel',
				icon: 'RecentActors',
			},
			// Close
			collapse: {
				id: 'collapse',
				text: 'Collapse',
				path: 'components/collapse',
				icon: 'UnfoldLess',
			},
			dropdowns: {
				id: 'dropdowns',
				text: 'Dropdowns',
				path: 'components/dropdowns',
				icon: 'Inventory',
			},
			listGroup: {
				id: 'listGroup',
				text: 'List Group',
				path: 'components/list-group',
				icon: 'ListAlt',
			},
			modal: {
				id: 'modal',
				text: 'Modal',
				path: 'components/modal',
				icon: 'PictureInPicture',
			},
			navsTabs: {
				id: 'navsTabs',
				text: 'Navs & Tabs',
				path: 'components/navs-and-tabs',
				icon: 'PivotTableChart',
			},
			// Navbar
			offcanvas: {
				id: 'offcanvas',
				text: 'Offcanvas',
				path: 'components/offcanvas',
				icon: 'VerticalSplit',
			},
			pagination: {
				id: 'pagination',
				text: 'Pagination',
				path: 'components/pagination',
				icon: 'Money',
			},
			popovers: {
				id: 'popovers',
				text: 'Popovers',
				path: 'components/popovers',
				icon: 'Assistant',
			},
			progress: {
				id: 'progress',
				text: 'Progress',
				path: 'components/progress',
				icon: 'HourglassTop',
			},
			scrollspy: {
				id: 'scrollspy',
				text: 'Scrollspy',
				path: 'components/scrollspy',
				icon: 'KeyboardHide',
			},
			spinners: {
				id: 'spinners',
				text: 'Spinners',
				path: 'components/spinners',
				icon: 'RotateRight',
			},
			table: {
				id: 'table',
				text: 'Table',
				path: 'components/table',
				icon: 'TableChart',
			},
			toasts: {
				id: 'toasts',
				text: 'Toasts',
				path: 'components/toasts',
				icon: 'RotateRight',
			},
			tooltip: {
				id: 'tooltip',
				text: 'Tooltip',
				path: 'components/tooltip',
				icon: 'Assistant',
			},
		},
	},
	forms: {
		id: 'forms',
		text: 'Forms',
		path: 'forms',
		icon: 'CheckBox',
		notification: 'success',
		subMenu: {
			formGroup: {
				id: 'formGroup',
				text: 'Form Group',
				path: 'forms/form-group',
				icon: 'Source',
			},
			formControl: {
				id: 'formControl',
				text: 'Form Controls',
				path: 'forms/form-controls',
				icon: 'Create',
			},
			select: {
				id: 'select',
				text: 'Select',
				path: 'forms/select',
				icon: 'Checklist',
			},
			checksAndRadio: {
				id: 'checksAndRadio',
				text: 'Checks & Radio',
				path: 'forms/checks-and-radio',
				icon: 'CheckBox',
			},
			range: {
				id: 'range',
				text: 'Range',
				path: 'forms/range',
				icon: 'HdrStrong',
			},
			inputGroup: {
				id: 'inputGroup',
				text: 'Input Group',
				path: 'forms/input-group',
				icon: 'PowerInput',
			},
			validation: {
				id: 'validation',
				text: 'Validation',
				path: 'forms/validation',
				icon: 'VerifiedUser',
			},
			wizard: {
				id: 'wizard',
				text: 'Wizard',
				path: 'forms/wizard',
				icon: 'LinearScale',
			},
		},
	},
	content: {
		id: 'content',
		text: 'Content',
		path: 'content',
		icon: 'format_size',
		subMenu: {
			typography: {
				id: 'typography',
				text: 'Typography',
				path: 'content/typography',
				icon: 'text_fields',
			},
			images: {
				id: 'images',
				text: 'Images',
				path: 'content/images',
				icon: 'Image ',
			},
			tables: {
				id: 'tables',
				text: 'Tables',
				path: 'content/tables',
				icon: 'table_chart',
			},
			figures: {
				id: 'figures',
				text: 'Figures',
				path: 'content/figures',
				icon: 'Photo Library ',
			},
		},
	},
	utilities: {
		id: 'utilities',
		text: 'Utilities',
		path: 'utilities',
		icon: 'Support',
		subMenu: {
			api: {
				id: 'api',
				text: 'API',
				path: 'utilities/api',
				icon: 'Api',
			},
			background: {
				id: 'background',
				text: 'Background',
				path: 'utilities/background',
				icon: 'FormatColorFill',
			},
			borders: {
				id: 'borders',
				text: 'Borders',
				path: 'utilities/borders',
				icon: 'BorderStyle',
			},
			colors: {
				id: 'colors',
				text: 'Colors',
				path: 'utilities/colors',
				icon: 'InvertColors',
			},
			display: {
				id: 'display',
				text: 'Display',
				path: 'utilities/display',
				icon: 'LaptopMac',
			},
			flex: {
				id: 'flex',
				text: 'Flex',
				path: 'utilities/flex',
				icon: 'SettingsOverscan',
			},
			float: {
				id: 'float',
				text: 'Float',
				path: 'utilities/float',
				icon: 'ViewArray',
			},
			interactions: {
				id: 'interactions',
				text: 'Interactions',
				path: 'utilities/interactions',
				icon: 'Mouse',
			},
			overflow: {
				id: 'overflow',
				text: 'Overflow',
				path: 'utilities/overflow',
				icon: 'TableRows',
			},
			position: {
				id: 'position',
				text: 'Position',
				path: 'utilities/position',
				icon: 'Adjust',
			},
			shadows: {
				id: 'shadows',
				text: 'Shadows',
				path: 'utilities/shadows',
				icon: 'ContentCopy',
			},
			sizing: {
				id: 'sizing',
				text: 'Sizing',
				path: 'utilities/sizing',
				icon: 'Straighten',
			},
			spacing: {
				id: 'spacing',
				text: 'Spacing',
				path: 'utilities/spacing',
				icon: 'SpaceBar',
			},
			text: {
				id: 'text',
				text: 'Text',
				path: 'utilities/text',
				icon: 'TextFields',
			},
			verticalAlign: {
				id: 'vertical-align',
				text: 'Vertical Align',
				path: 'utilities/vertical-align',
				icon: 'VerticalAlignCenter',
			},
			visibility: {
				id: 'visibility',
				text: 'Visibility',
				path: 'utilities/visibility',
				icon: 'Visibility',
			},
		},
	},
	extra: {
		id: 'extra',
		text: 'Extra Library',
		icon: 'Extension',
		path: undefined,
	},
	icons: {
		id: 'icons',
		text: 'Icons',
		path: 'icons',
		icon: 'Grain',
		notification: 'success',
		subMenu: {
			icon: {
				id: 'icon',
				text: 'Icon',
				path: 'icons/icon',
				icon: 'Lightbulb',
			},
			material: {
				id: 'material',
				text: 'Material',
				path: 'icons/material',
				icon: 'Verified',
			},
		},
	},
	charts: {
		id: 'charts',
		text: 'Charts',
		path: 'charts',
		icon: 'AreaChart',
		notification: 'success',
		subMenu: {
			chartsUsage: {
				id: 'chartsUsage',
				text: 'General Usage',
				path: 'charts/general-usage',
				icon: 'Description',
			},
			chartsSparkline: {
				id: 'chartsSparkline',
				text: 'Sparkline',
				path: 'charts/sparkline',
				icon: 'AddChart',
			},
			chartsLine: {
				id: 'chartsLine',
				text: 'Line',
				path: 'charts/line',
				icon: 'ShowChart',
			},
			chartsArea: {
				id: 'chartsArea',
				text: 'Area',
				path: 'charts/area',
				icon: 'AreaChart',
			},
			chartsColumn: {
				id: 'chartsColumn',
				text: 'Column',
				path: 'charts/column',
				icon: 'BarChart',
			},
			chartsBar: {
				id: 'chartsBar',
				text: 'Bar',
				path: 'charts/bar',
				icon: 'StackedBarChart',
			},
			chartsMixed: {
				id: 'chartsMixed',
				text: 'Mixed',
				path: 'charts/mixed',
				icon: 'MultilineChart',
			},
			chartsTimeline: {
				id: 'chartsTimeline',
				text: 'Timeline',
				path: 'charts/timeline',
				icon: 'WaterfallChart',
			},
			chartsCandleStick: {
				id: 'chartsCandleStick',
				text: 'Candlestick',
				path: 'charts/candlestick',
				icon: 'Cake',
			},
			chartsBoxWhisker: {
				id: 'chartsBoxWhisker',
				text: 'Box Whisker',
				path: 'charts/box-whisker',
				icon: 'SportsMma',
			},
			chartsPieDonut: {
				id: 'chartsPieDonut',
				text: 'Pie & Donut',
				path: 'charts/pie-donut',
				icon: 'PieChart',
			},
			chartsRadar: {
				id: 'chartsRadar',
				text: 'Radar',
				path: 'charts/radar',
				icon: 'BrightnessLow',
			},
			chartsPolar: {
				id: 'chartsPolar',
				text: 'Polar',
				path: 'charts/polar',
				icon: 'TrackChanges',
			},
			chartsRadialBar: {
				id: 'chartsRadialBar',
				text: 'Radial Bar',
				path: 'charts/radial-bar',
				icon: 'DonutLarge',
			},
			chartsBubble: {
				id: 'chartsBubble',
				text: 'Bubble',
				path: 'charts/bubble',
				icon: 'BubbleChart',
			},
			chartsScatter: {
				id: 'chartsScatter',
				text: 'Scatter',
				path: 'charts/scatter',
				icon: 'ScatterPlot',
			},
			chartsHeatMap: {
				id: 'chartsHeatMap',
				text: 'Heat Map',
				path: 'charts/heat-map',
				icon: 'GridOn',
			},
			chartsTreeMap: {
				id: 'chartsTreeMap',
				text: 'Tree Map',
				path: 'charts/tree-map',
				icon: 'AccountTree',
			},
		},
	},
	notification: {
		id: 'notification',
		text: 'Notification',
		path: 'notifications',
		icon: 'NotificationsNone',
	},
	hooks: {
		id: 'hooks',
		text: 'Hooks',
		path: 'hooks',
		icon: 'Anchor',
	},
};

export const dashboardPagesMenu = {
	dashboard: {
		id: 'dashboard',
		text: 'My Household',
		path: '/dashboard',
		icon: 'FamilyRestroom',
		subMenu: null,
	},
	householdBudget: {
		id: 'householdBudget',
		text: 'Household Budget',
		path: '/budget-setup',
		icon: 'MonetizationOn',
		subMenu: null,
	},
	wallet: {
		id: 'wallet',
		text: 'My Wallet',
		path: "/wallet-log",
		icon: 'AccountBalanceWallet'
	},
	children: {
		id: 'children',
		text: 'Children',
		path: '/child-list',
		icon: 'ChildCare',
		subMenu: {
			childInfo: {
				id: 'childInfo',
				text: 'Child List',
				path: '/child-list',
				icon: 'AccountTree',
			},
			addChild: {
				id: 'addChild',
				text: 'Add Child',
				path: '/child-setup',
				icon: 'AccountTree',
			},
		},
	},
	users: {
		id: 'users',
		text: 'Parents',
		path: '/users',
		icon: 'Group',
		subMenu: {
			userInfo: {
				id: 'userInfo',
				text: 'Parent List',
				path: '/user-list',
				icon: 'AccountTree',
			},
			addUser: {
				id: 'addUser',
				text: 'Add Parent',
				path: '/user-setup',
				icon: 'AccountTree',
			},
		},
	},
	chores: {
		id: 'chores',
		text: 'Chores',
		path: '/chore-plan',
		icon: 'Event',
		subMenu: {
			chorePlans: {
				id: "chorePlans",
				text: 'Chore Plans',
				path: '/view-chore-plans',
				icon: 'AccountTree'
			},
			choreAllocation: {
				id: "choreAllocation",
				text: 'Chore Allocation',
				path: '/chore-allocation',
				icon: 'AccountTree'
			}
		}
	},
	fitness: {
		id: 'fitness',
		text: 'Fitness',
		path: '/fitness-plan',
		icon: 'AccessibilityNew',
		subMenu: {
			fitnessPlans: {
				id: "fitnessPlans",
				text: 'Fitness Plans',
				path: '/fitness-plan',
				icon: 'AccountTree'
			},
			fitnessAllocation: {
				id: "fitnessAllocation",
				text: 'Fitness Allocation',
				path: '/fitness-allocation',
				icon: 'AccountTree'
			}
		}
	},
	academics: {
		id: 'academics',
		text: 'Academics',
		path: '/academics',
		icon: 'MenuBook',
		subMenu: {
			schoolInfo: {
				id: "schoolInfo",
				text: 'Schools',
				path: '/academics',
				icon: 'AccountTree'
			},
			childReportCards: {
				id: "childReportCards",
				text: 'Child Report Cards',
				path: '/child-report-cards',
				icon: 'AccountTree'
			}
		}
	},
	rewardGenie: {
		id: 'rewardGenie',
		text: 'Reward Genie',
		path: '/my-rewards',
		icon: 'CardGiftcard',
		subMenu: {
			wishlist: {
				id: 'wishlist',
				text: 'Wishlist',
				path: '/wishlist',
				icon: 'AccountTree',
			},
			rewardsList: {
				id: 'rewardsList',
				text: "Rewards",
				path: "/rewards-list",
				icon: 'AccountTree'
			}
		},
	},
};

export const demoPagesMenu = {
	auth: {
		id: 'auth',
		text: 'Auth Pages',
		icon: 'Extension',
	},
	home: {
		id: 'home',
		text: 'Home',
		path: '/',
		icon: 'Login'
	},
	privacy: {
		id: 'privacy',
		text: 'Privacy Policy',
		path: '/privacy-policy',
		icon: 'PrivacyTip'
	},
	contact: {
		id: 'contact',
		text: 'Contact Us',
		path: '/contact-us',
		icon: 'Contacts'
	},
	login: {
		id: 'login',
		text: 'Login',
		path: 'auth/login',
		icon: 'Login',
	},
	signUp: {
		id: 'signUp',
		text: 'Sign Up',
		path: 'auth/sign-up',
		icon: 'PersonAdd',
	},
	page404: {
		id: 'Page404',
		text: '404 Page',
		path: 'auth/404',
		icon: 'ReportGmailerrorred',
	},
};

export const extraMenu = {
	extra: {
		id: 'extra',
		text: 'Extra',
		icon: 'Extension',
	},
	upForGrabs: {
		id: 'upForGrabs',
		text: 'Up-For-Grabs',
		path: '/up-for-grabs',
		icon: 'LocalOffer',
		// subMenu: {
		// 	grabsList: {
		// 		id: 'grabsList',
		// 		text: 'Grabs List',
		// 		path: '/grabs-list',
		// 		icon: 'ListAlt',
		// 	},
		// 	grabsClaimed: {
		// 		id: 'grabsClaimed',
		// 		text: 'Grabs Claimed',
		// 		path: '/grabs-claimed',
		// 		icon: 'Celebration',
		// 	},
		// }
	},
	growthTracker: {
		id: 'growthTracker',
		text: 'Growth Tracker',
		path: '/tracker',
		icon: 'Height'
	},
	notifications: {
		id: 'notifications',
		text: 'Notifications',
		path: '/notifications',
		icon: 'NotificationsNone'
	},
	messaging: {
		id: 'messaging',
		text: 'Messaging',
		path: '/messages',
		icon: 'QuestionAnswer',
		// subMenu: {
		// 	contacts: {
		// 		id: 'contacts',
		// 		text: "My Contact Cards",
		// 		path: '/my-contacts',
		// 		icon: 'ContactPhone'
		// 	},
		// 	compose: {
		// 		id: 'compose',
		// 		text: "Compose",
		// 		path: '/compose-message',
		// 		icon: 'EditNote'
		// 	},
		// 	inbox: {
		// 		id: 'inbox',
		// 		text: "My Inbox",
		// 		path: '/inbox',
		// 		icon: 'Inbox'
		// 	},
		// 	outbox: {
		// 		id: 'outbox',
		// 		text: "My Outbox",
		// 		path: '/outbox',
		// 		icon: 'Outbox'
		// 	},
		// 	trash: {
		// 		id: 'trash',
		// 		text: "Trash Can",
		// 		path: '/trash',
		// 		icon: 'Delete'
		// 	}
		// },
	},
	support: {
		id: 'support',
		text: 'Support',
		path: '/support',
		icon: 'Support',
		subMenu: {
			workWithUs: {
				id: 'workWithUs',
				text: "Work With Us",
				path: '/work-with-us',
				icon: 'AccountTree'
			},
			advertiseWithUs: {
				id: 'advertiseWithUs',
				text: "Advertise With Us",
				path: '/advertise-with-us',
				icon: 'AccountTree'
			},
			contactUs: {
				id: 'contactUs',
				text: "Contact Us",
				path: '/contact',
				icon: 'AccountTree'
			},
			explore: {
				id: 'explore',
				text: "Explore",
				path: '/explore',
				icon: 'AccountTree'
			}
		},
	},
};


export const pageLayoutTypesPagesMenu = {
	layoutTypes: {
		id: 'layoutTypes',
		text: 'Page Layout Types',
	},
	blank: {
		id: 'blank',
		text: 'Blank',
		path: 'page-layouts/blank',
		icon: 'check_box_outline_blank ',
	},
	pageLayout: {
		id: 'pageLayout',
		text: 'Page Layout',
		path: 'page-layouts',
		icon: 'BackupTable',
		subMenu: {
			headerAndSubheader: {
				id: 'headerAndSubheader',
				text: 'Header & Subheader',
				path: 'page-layouts/header-and-subheader',
				icon: 'ViewAgenda',
			},
			onlyHeader: {
				id: 'onlyHeader',
				text: 'Only Header',
				path: 'page-layouts/only-header',
				icon: 'ViewStream',
			},
			onlySubheader: {
				id: 'onlySubheader',
				text: 'Only Subheader',
				path: 'page-layouts/only-subheader',
				icon: 'ViewStream',
			},
			onlyContent: {
				id: 'onlyContent',
				text: 'Only Content',
				path: 'page-layouts/only-content',
				icon: 'WebAsset',
			},
		},
	},
	asideTypes: {
		id: 'asideTypes',
		text: 'Aside Types',
		path: 'aside-types',
		icon: 'Vertical Split',
		subMenu: {
			defaultAside: {
				id: 'defaultAside',
				text: 'Default Aside',
				path: 'aside-types/default-aside',
				icon: 'ViewQuilt',
			},
			minimizeAside: {
				id: 'minimizeAside',
				text: 'Minimize Aside',
				path: 'aside-types/minimize-aside',
				icon: 'View Compact',
			},
		},
	},
};

export const productsExampleMenu = {
	companyA: { id: 'companyA', text: 'Company A', path: 'grid-pages/products', subMenu: null },
	companyB: { id: 'companyB', text: 'Company B', path: '/', subMenu: null },
	companyC: { id: 'companyC', text: 'Company C', path: '/', subMenu: null },
	companyD: { id: 'companyD', text: 'Company D', path: '/', subMenu: null },
};

export const setupMenu = {
	child: {
		id: 'childSetup',
		text: '',
		path: 'child-setup'
	},
	budget: {
		id: 'budgetSetup',
		text: '',
		path: 'budget-setup'
	},
	choresCustomSetup: {
		id: 'choreSetup',
		text: '',
		path: 'chore-custom-setup'
	},
	choresInbuildSetup: {
		id: 'choreInbuildSetup',
		text: '',
		path: 'chore-inbuild-setup'
	},
	fitnessCustomSetup: {
		id: 'fitnessSetup',
		text: '',
		path: 'fitness-custom-setup'
	},
	fitnessInbuildSetup: {
		id: 'fitnessInbuildSetup',
		text: '',
		path: 'fitness-inbuild-setup'
	},
	academicSetup: {
		id: 'academicSetup',
		text: '',
		path: 'academics-setup'
	},
	academicDetails: {
		id: 'academicDetails',
		text: '',
		path: 'academics-details'
	},
	childAcademicSetup: {
		id: 'academicSetup',
		text: '',
		path: 'child-academics-setup'
	},
}

export const usersListMenu = {
	childUsers: {
		id: 'childList',
		text: '',
		path: 'child-list'
	},
	chorePlans: {
		id: 'chorePlan',
		text: '',
		path: 'chore-plan'
	},
	fitnessPlans: {
		id: 'fitnessPlan',
		text: '',
		path: 'fitness-plan'
	},
	academics: {
		id: 'academics',
		text: '',
		path: 'academics'
	},
	siblingInfo: {
		id: 'shibling',
		// text: 'My Sibling',
		path: "/shibling-info",
		// icon: 'AccountTree'
	}
}

export const childDashboardPagesMenu = {
	dashboard: {
		id: 'dashboard',
		text: 'My Home',
		path: '/dashboard',
		icon: 'Home',
		subMenu: null,
	},
	// childDashboard: {
	// 	id: 'DashboardParent',
	// 	text: 'Child Dashboard',
	// 	path: '/child-dashboard',
	// 	icon: 'FamilyRestroom',
	// 	subMenu: null,
	// },
	myInfo: {
		id: 'myInfo',
		text: 'My Info',
		path: '/my-info',
		icon: 'Info',
		subMenu: {
			myProfile: {
				id: 'myProfile',
				text: 'My Profile',
				path: "/profile",
				icon: 'AccountTree'
			},
			wallet: {
				id: 'wallet',
				text: 'My Wallet',
				path: "/wallet-details",
				icon: 'AccountTree'
			},
			// siblingInfo: {
			// 	id: 'shibling',
			// 	// text: 'My Sibling',
			// 	path: "/shibling-info",
			// 	// icon: 'AccountTree'
			// }

		},
	},
	myfamily: {
		id: 'myfamily',
		text: 'My Family',
		path: '/my-family',
		icon: 'FamilyRestroom',
		subMenu: {
			myParents: {
				id: 'myParents',
				text: 'My Parents',
				path: '/parent-list',
				icon: 'AccountTree',
			},
			mySiblings: {
				id: 'mySiblings',
				text: 'My Siblings',
				path: '/my-siblings',
				icon: 'AccountTree',



			}
		}
	},
	myChores: {
		id: 'myChores',
		text: 'My Chores',
		path: '/my-chores',
		icon: 'Event',
		subMenu: null
	},
	myFitness: {
		id: 'myFitness',
		text: 'My Fitness Plans',
		path: '/fitness-plans',
		icon: 'AccessibilityNew',
		subMenu: null
	},
	myAcademics: {
		id: 'myAcademics',
		text: 'My Academics',
		path: '/academics',
		icon: 'MenuBook',
		subMenu: {
			schoolInfo: {
				id: 'schoolInfo',
				text: "My School",
				path: "/school-info",
				icon: 'AccountTree'
			},
			reportCard: {
				id: 'reportCard',
				text: "My Report Cards",
				path: "/report-card",
				icon: 'AccountTree'
			},
		}
	},
	myRewards: {
		id: 'myRewards',
		text: 'Reward Genie',
		path: '/my-rewards',
		icon: 'CardGiftcard',
		subMenu: {
			myRewards: {
				id: 'myRewards',
				text: "Dashboard",
				path: "/my-rewards",
				icon: 'AccountTree'
			},
			myWishList: {
				id: 'myWishList',
				text: "My Wishlist",
				path: "/my-wishlist",
				icon: 'AccountTree'
			},
			myRewardsList: {
				id: 'myrewardList',
				text: "My Rewards",
				path: "/my-rewardlist",
				icon: 'AccountTree'
			},
			myClaims: {
				id: 'myClaimList',
				text: "My Claims",
				path: "/my-claims",
				icon: 'AccountTree'
			},
		},
	},
	// extras: {
	// 	id: 'extras',
	// 	text: 'Extras',
	// 	path: '/extras',
	// 	icon: 'Category',
	// 	subMenu: {
	// 		// wishlist: {
	// 		// 	id: 'wishlist',
	// 		// 	text: 'Wish List',
	// 		// 	path: '/wishlist',
	// 		// 	icon: 'StarHalf'
	// 		// },
	// 		upforGrabs: {
	// 			id: 'upForGrabs',
	// 			text: 'My Up For Grabs',
	// 			path: '/my-up-for-grabs',
	// 			icon: 'LocalOffer'
	// 		},
	// 		growthTracker: {
	// 			id: 'growthTracker',
	// 			text: 'My Growth Tracker',
	// 			path: '/tracker',
	// 			icon: 'Height'
	// 		},
	// 		notifications: {
	// 			id: 'notifications',
	// 			text: 'My Notifications',
	// 			path: '/notifications',
	// 			icon: 'NotificationsNone'
	// 		},
	// 		gameCenter: {
	// 			id: 'gameCenter',
	// 			text: 'Game Center',
	// 			path: '/game-center',
	// 			icon: 'Games'
	// 		}
	// 	}
	// }
};

export const childExtraMenu = {
	extra: {
		id: 'extra',
		text: 'Extra',
		icon: 'Extension',
	},
	upforGrabs: {
		id: 'upForGrabs',
		text: 'My Up For Grabs',
		path: '/my-up-for-grabs',
		icon: 'LocalOffer'
	},
	growthTracker: {
		id: 'growthTracker',
		text: 'My Growth Tracker',
		path: '/tracker',
		icon: 'Height'
	},
	notifications: {
		id: 'notifications',
		text: 'My Notifications',
		path: '/my-notifications',
		icon: 'NotificationsNone'
	},
	gameCenter: {
		id: 'gameCenter',
		text: 'Game Center',
		path: '/game-center',
		icon: 'Games'
	},
	messaging: {
		id: 'messaging',
		text: 'Messaging',
		path: '/messages',
		icon: 'QuestionAnswer',
		// subMenu: {
		// 	contacts: {
		// 		id: 'contacts',
		// 		text: "My Contact Cards",
		// 		path: '/my-contacts',
		// 		icon: 'ContactPhone'
		// 	},
		// 	compose: {
		// 		id: 'compose',
		// 		text: "Compose",
		// 		path: '/compose-message',
		// 		icon: 'EditNote'
		// 	},
		// 	inbox: {
		// 		id: 'myInbox',
		// 		text: "My Inbox",
		// 		path: '/inbox',
		// 		icon: 'Inbox'
		// 	},
		// 	outbox: {
		// 		id: 'myOutbox',
		// 		text: "My Outbox",
		// 		path: '/outbox',
		// 		icon: 'Outbox'
		// 	},
		// 	trash: {
		// 		id: 'trash',
		// 		text: "Trash Can",
		// 		path: '/trash',
		// 		icon: 'Delete'
		// 	}
		// },
	},
	support: {
		id: 'support',
		text: 'Support',
		path: '/support',
		icon: 'Support',
		subMenu: {
			workWithUs: {
				id: 'workWithUs',
				text: "Work With Us",
				path: '/work-with-us',
				icon: 'AccountTree'
			},
			advertiseWithUs: {
				id: 'advertiseWithUs',
				text: "Advertise With Us",
				path: '/advertise-with-us',
				icon: 'AccountTree'
			},
			contactUs: {
				id: 'contactUs',
				text: "Contact Us",
				path: '/contact-us',
				icon: 'AccountTree'
			},
			explore: {
				id: 'explore',
				text: "Explore",
				path: '/explore',
				icon: 'AccountTree'
			}
		},
	},
}

export const detailsPagesMenu = {
	choreDetails: {
		path: '/view-chore-details',
	},
	fitnessDetails: {
		path: '/view-fitness-details',
	},
	choreDetailsParentView: {
		path: '/view-chore-details-parent',
	},
	fitnessDetailsParentView: {
		path: '/view-fitness-details-parent',
	},
	HouseholdDetailsSuperAdminView: {
		path: '/household-details',
	},
	ParentDetailsSuperAdminView: {
		path: '/view-parent-details',
	},
	childDetailsSuperAdminView: {
		path: '/view-child-details',
	},
	grabDetailsChildView: {
		path: '/my-grab-details'
	},
	grabDetailsParentView: {
		path: '/grab-details'
	},
	childWalletDetailsView: {
		path: '/wallet-details'
	},
	growthDetailsParentView: {
		path: '/growth-details-parent'
	},
	childReportCardDetails: {
		path: '/child-report-details'
	},
	childReportUploadSection: {
		path: '/child-report-upload'
	},
	rewardDetailsParent: {
		path: '/reward-details'
	},
	parentInformationDetails: {
		path: '/parent-profile'
	},
	rewardsRecordsChild: {
		path: '/reward-records'
	},
	rewardsDetailsChild: {
		path: '/reward-details-child'
	},
	parentDetailsView: {
		path: '/parent-view'
	},
	adminReports: {
		subscriptionRegReport: {
			path: '/admin-subscription-registration-report',
		},
		userRegistrationReport: {
			path: '/admin-user-registration-report'
		},
		choreReport: {
			path: '/admin-chore-report'
		},
		fitnessReport: {
			path: '/admin-fitness-report'
		},
		grabsReport: {
			path: '/admin-grabs-report'
		},
		budgetReport: {
			path: '/admin-budget-report'
		},
		choreBudgetReport: {
			path: '/admin-chore-budget-report'
		},
		fitnessBudgetReport: {
			path: '/admin-fitness-budget-report'
		}
	}
}

export const adminDashboardPagesMenu = {
	dashboard: {
		id: 'dashboard',
		text: 'Dashboard',
		path: '/dashboard',
		icon: 'Home',
		// subMenu: null,
	},
	household: {
		id: 'household',
		text: 'Households',
		path: '/household',
		icon: 'HolidayVillage',
		subMenu: {
			houseHoldList: {
				id: 'houseHoldList',
				text: 'Households List',
				path: '/household-list',
				icon: 'AccountTree',
			},
			parentList: {
				id: 'parentList',
				text: 'Parent List',
				path: '/admin-parent-list',
				icon: 'AccountTree',
			},
			childList: {
				id: 'childList',
				text: 'Child List',
				path: '/admin-child-list',
				icon: 'AccountTree',
			},
			// reports: {
			// 	id: 'reports',
			// 	text: 'Reports',
			// 	path: '/household-report',
			// 	icon: 'BubbleChart',
			// }
		},
	},
	chorePlans: {
		id: 'chorePlans',
		text: 'Chore Plans',
		path: '/household-plans',
		icon: 'Event',
		subMenu: {
			// chorePlans: {
			// 	id: 'chorePlans',
			// 	text: 'Chore Plans',
			// 	path: '/chore-plans',
			// 	icon: 'ListAlt',
			// },
			choreDashboard: {
				id: 'choreDashboard',
				text: 'Chore Dashboard',
				path: '/admin-chore-dashboard',
				icon: 'AccountTree',
			},
			choreTemplate: {
				id: 'choreTemplate',
				text: 'Chore Template',
				path: '/chore-template',
				icon: 'AccountTree',
			},
			setCategory: {
				id: 'setCategory',
				text: 'Set Category',
				path: '/set-chore-category',
				icon: 'AccountTree',
			},
		}
	},
	fitnessPlans: {
		id: 'fitnessPlans',
		text: 'Fitness Plans',
		path: '/fitness-plans',
		icon: 'AccessibilityNew',
		subMenu: {
			// fitnessPlans: {
			// 	id: 'fitnessPlans',
			// 	text: 'Fitness Plans',
			// 	path: '/fitness-plans',
			// 	icon: 'ListAlt',
			// },
			fitnessDashboard: {
				id: 'fitnessDashboard',
				text: 'Fitness Dashboard',
				path: '/admin-fitness-dashboard',
				icon: 'AccountTree',
			},
			choreTemplate: {
				id: 'choreTemplate',
				text: 'Fitness Template',
				path: '/fitness-template',
				icon: 'AccountTree',
			},
			setCategory: {
				id: 'setCategory',
				text: 'Set Category',
				path: '/set-fitness-category',
				icon: 'AccountTree',
			},
		}
	},
	
	academics: {
		id: 'academics',
		text: 'Academics',
		path: '/academics-admin',
		icon: 'MenuBook'
	},
	hobbies: {
		id: 'hobbies',
		text: 'Hobbies',
		path: '/hobbies-admin',
		icon: 'MenuBook'
	},
	rewards: {
		id: 'rewards',
		text: 'Rewards',
		path: '/rewards',
		icon: 'CardGiftcard',
		subMenu: {
			retailers: {
				id: 'retailers',
				text: 'Retailers',
				path: '/retailers',
				icon: 'AccountTree',
			}
		},
	},
	billing: {
		id: 'billing',
		text: 'Billing',
		path: '/billing',
		icon: 'AttachMoney',
		subMenu: {
			userPlans: {
				id: 'userPlans',
				text: 'User Plans',
				path: '/user-plans',
				icon: 'AccountTree',
			}
		},
	},
	training: {
		id: 'training',
		text: 'Training',
		path: '/training',
		icon: 'AssignmentTurnedIn'
	},
	// adManage: {
	// 	id: 'adManage',
	// 	text: 'Ad Management',
	// 	path: '/ad-management',
	// 	icon: 'PostAdd'
	// },
	reports: {
		id: 'reports',
		text: 'Reports',
		path: '/reports',
		icon: 'BarChart'
	},
	myInfo: {
		id: 'myInfo',
		text: 'My Info',
		path: '/my-info',
		icon: 'Info',

	},
};

export const comingSoonPage = {
	path: '/coming-soon'
}

export const releaseSoonPage = {
	release: {
		id: '',
		text: '',
		path: '/release-notes',
		icon: ''
	}
}