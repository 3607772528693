import React, { useState } from 'react';
import classNames from 'classnames';
import useDarkMode from '../../../hooks/useDarkMode';
import Footer from '../../../layout/Footer/Footer';
// import ddMiniLogo from '../../../assets/img/dd-logo-nav.png';
import ddMiniLogo from '../../../assets/img/dd_footer-logo-nav.png';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import Icon from '../../../components/icon/Icon';
import OffCanvas, { OffCanvasBody, OffCanvasHeader } from '../../../components/bootstrap/OffCanvas';
import Chat, { ChatGroup, ChatHeader } from '../../../components/Chat';
import InputGroup from '../../../components/bootstrap/forms/InputGroup';
import Textarea from '../../../components/bootstrap/forms/Textarea';
import Button from '../../../components/bootstrap/Button';
import { CHLOE_VS_JOHN } from '../../../common/default/chatData';
import childStaticimg from '../../../assets/img/childStaticimg.png';
import { UserProfileType } from '../../../common/enum/enumUserProfileTypes';
import { storageObj } from '../../../common/default/defaultStorage';


const DefaultFooter = () => {
	const { darkModeStatus } = useDarkMode();
	const { t } = useTranslation();
	const navigate = useNavigate();
	const [state, setState] = useState<boolean>(false);
	const user_type_id = storageObj.getUserTypeId();
	const msgCount = sessionStorage.getItem('messageListLength');

	return (
		<Footer>
			<div className='container-fluid'>
				<div className='d-flex flex-column flex-md-row align-items-center justify-content-between'>
					<div>
						<span className='fw-bold'>{t('copyright')}{new Date().getFullYear()}</span>
					</div>
					<div className='mt-2 mt-md-0'>
						<em className='fw-600 text-red family-cursive'>{t('disclaimer')}</em>
					</div>
					<div className='d-flex align-items-center'>
						{user_type_id != UserProfileType.Admin &&
							<>
								<div className='position-relative'>
									<Icon
										icon={'QuestionAnswer'}
										color={'danger'} // null || 'primary' || 'secondary' || 'success' || 'info' || 'warning' || 'danger' || 'light' || 'dark'
										size={'2x'} // null || 'sm' || 'md' || 'lg' || '2x' || '3x' || '4x' || '5x' || '6x' || '7x' || '8x' || '9x' || '10x'
										className='me-3 cursor-pointer'
										// onClick={()=>setState(!state)}
										onClick={() => navigate('/messages')}
									/>
									<span className='footer-msg-notification'>{msgCount}</span>
								</div>
							</>
							// <div className='col-auto notificationsWrap'>
							// 	<Button
							// 		icon='QuestionAnswer'
							// 		onClick={() => navigate('/messages')}
							// 		aria-label='QuestionAnswer'
							// 		className='notifications'
							// 	/>
							// 	<span className='counts'>{msgCount}</span>
							// </div>
						}
						<a
							href='/'
							className={classNames('text-decoration-none', {
								'link-dark': !darkModeStatus,
								'link-light': darkModeStatus,
							})}>
							<img src={ddMiniLogo} className='img-fluid me-2' alt='Diligent Dollar Logo' style={{ height: '50px' }} />
						</a>
						{' '}
						<b className='cursor-pointer' onClick={() => navigate('/release-notes')}>v1.1.1</b>
					</div>
				</div>
			</div>

			{/* Chat Start */}

			{/* <OffCanvas
				id='chat'
				isOpen={state}
				setOpen={setState}
				placement='end'
				isModalStyle
				isBackdrop={false}
				isBodyScroll>
				<OffCanvasHeader setOpen={setState} className='fs-5'>
					<ChatHeader to={'CHLOE'} />
				</OffCanvasHeader>
				<OffCanvasBody>
					<Chat>
						{CHLOE_VS_JOHN.map((msg: any) => (
							<ChatGroup
								key={msg.id}
								messages={msg.messages}
								// user={msg.user}
								// src={!!userData?.full_profile_picture_path ? `${userData?.full_profile_picture_path}` : String(childStaticimg)}
								// user={String(childStaticimg)}
								src={String(childStaticimg)}
								isReply={msg.isReply}
							/>
						))}
					</Chat>
				</OffCanvasBody>
				<div className='chat-send-message p-3'>
					<InputGroup>
						<Textarea />
						<Button color='info' icon='Send'>
							SEND
						</Button>
					</InputGroup>
				</div>
			</OffCanvas> */}

			{/* Chat End */}
		</Footer>
	);
};

export default DefaultFooter;
